<template>
  <div class="InitContract">
  </div>
</template>

<script>
import MetaMaskSDK from "@metamask/sdk";
import detectEthereumProvider from '@metamask/detect-provider'
import Web3 from "web3";
import { Chain, addresses, ABI, ConnectStatus } from './Config';
import WalletConnectProvider from "@walletconnect/web3-provider";
import { providers } from "ethers";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import Web3Modal from "web3modal";
export default {
  // name: "HelloWorld",
  props: {
  },
  data() {
    return {
      unit: Web3.utils.toWei("1", "ether"),
      web3: null,
      account: null,
      signature: null,
      balance: 0,
      chainId: "",
      targetChainId: -1,
      bOnTest: Chain.bOnTest,
      bInWhiteList: true,

      connectHandler: null,
      transcationHash: [],

      BearCoin: null,
      BearNFT: null,
      LandNFT: null,
      IsaacCenter: null,
      defaultAddress:
        "0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff",
    };
  },
  methods: {
    async connectByDetectEthereumProvider(TargetChain) {
      this.targetChainId = Chain.getTargetChainId(TargetChain);

      const provider = await detectEthereumProvider()
      console.log(provider, "========connectByDetectEthereumProvider=========>", TargetChain)
      // provider.autoRefreshOnNetworkChange = false;
      this.initHandler(provider);
      try {
        await provider.enable();
      } catch (error) {
        console.error("User denied account access");
      }

      this.web3 = new Web3(provider);
      const account = this.web3.currentProvider.selectedAddress || null;
      this.chainId = this.web3.currentProvider.networkVersion || null;
      this.handlerCallback([account]);
    },
    async connectBySdk(TargetChain) {
      this.targetChainId = Chain.getTargetChainId(TargetChain);

      const ethereum = new MetaMaskSDK({
        preferDesktop: true,
        injectProvider: false,
        communicationLayerPreference: "webrtc"
      });
      const provider = ethereum.getProvider();
      // provider.autoRefreshOnNetworkChange = false;
      this.initHandler(provider);
      try {
        await provider.enable();
      } catch (error) {
        console.error("User denied account access");
      }

      this.web3 = new Web3(provider);
      const account = this.web3.currentProvider.selectedAddress || null;
      this.chainId = this.web3.currentProvider.networkVersion || null;
      this.handlerCallback([account]);
    },
    init (callback) {
      this.account = null;
      this.chainId = "";
      this.targetChainId = -1;
      this.signature = null;
      this.balance = 0;
      this.bInWhiteList = true;
      this.transcationHash = [];
      this.BearCoin = null;
      this.BearNFT = null;
      this.LandNFT = null;
      this.IsaacCenter = null;
      this.connectHandler = callback;
    },
    async handlerCallback (accounts) {
      // console.log(this.web3, "        ============WEB3============>                ")
      console.log("accounts ==> ", accounts, "\n chainId ==> ", this.chainId);
      this.account = accounts[0];
      if (this.account) {
        if (this.targetChainId == -1) {
          if (Chain.isSupport(this.chainId)) this.login();
          else this.connectHandler(ConnectStatus.ChainError);
        } else {
          if (this.targetChainId == Number(this.chainId)) this.login();
          else this.connectHandler(ConnectStatus.ChainError);
        }
      } else this.connectHandler(ConnectStatus.Failure);
    },

    initHandler(provider) {
      provider.on("accountsChanged", (account) => {
        if (account[0] != this.account) {
          this.init(this.connectHandler);
          this.connectHandler(ConnectStatus.AccountChange);
        }
      });
      provider.on("chainChanged", (chainId) => {
        console.log("Chain Changed");
        this.chainId = this.web3.utils.hexToNumber(chainId);
        this.handlerCallback([this.account]);
      });
    },

    async connectWeb3(TargetChain) {
      //MetaMask
      // return this.connectWallet(TargetChain)
      // return this.connectOnto()
      // return this.connectWallet(TargetChain)
      // return this.connectCoinbase(TargetChain)
      this.targetChainId = Chain.getTargetChainId(TargetChain);
      console.log(this.targetChainId, "=================>");

      let web3Provider = null;
      // console.log(window.ethereum, " ethereum=====>")
      if (window.ethereum) {
        window.ethereum.autoRefreshOnNetworkChange = false;
        if (window.ethereum.providers) {
          for (let item of window.ethereum.providers) {
            if (item.isMetaMask) {
              //preferred select metamask, maybe item.isCoinbaseWallet
              web3Provider = item;
              break;
            }
          }
          if (web3Provider == null) web3Provider = window.ethereum.providers[0];
        } else {
          // window.ethereum.isTokenPocket = false;
          // window.ethereum.isDfox = false;
          // window.ethereum.isBitEthereum = false;
          // window.ethereum.isBitKeep = false;
          // window.ethereum.isBitKeepChrome = false;
          web3Provider = window.ethereum;
        }
        console.log(web3Provider, "==================>");
        // web3Provider = window.okexchain;
        this.initHandler(web3Provider);
        try {
          await web3Provider.enable();
        } catch (error) {
          console.error("User denied account access");
        }
      } else if (window.web3) {
        web3Provider = window.web3.currentProvider;
      } else {
        // return this.connectBySdk(TargetChain)
        let rpc_url = Chain.randomRpcUrl;
        if (this.targetChainId != -1) rpc_url = Chain.getChainConfig(this.targetChainId).rpc_url;
        web3Provider = new Web3.providers.HttpProvider(rpc_url);
      }
      this.web3 = new Web3(web3Provider);
      // this.web3.eth.getAccounts(function (error, result) {
      //   if (!error) console.log(result);
      // });
      const account = this.web3.currentProvider.selectedAddress || null;
      this.chainId = this.web3.currentProvider.networkVersion || null;
      this.handlerCallback([account]);
    },

    async connectOnto (TargetChain) {
      //Onto Wallet
      this.targetChainId = Chain.getTargetChainId(TargetChain);
      if (window.onto) {
        this.web3 = new Web3(window.onto);
        this.initHandler(window.onto);

        this.web3.eth.requestAccounts().then(() => {
          const account = window.onto.selectedAddress;
          this.chainId = window.onto.networkVersion;
          this.handlerCallback([account]);
        });
      }
    },

    async connectWallet(TargetChain) {
      //WalletConnect
      this.targetChainId = Chain.getTargetChainId(TargetChain);
      let TargetChainId = this.targetChainId;
      if (TargetChainId == -1) TargetChainId = Chain.randomChainId;
      const config = Chain.getChainConfig(TargetChainId);
      //  Create WalletConnect Provider
      const provider = new WalletConnectProvider({
        infuraId: "74defb559656aa82214768e522f02af6",
        bridge: "https://bridge.walletconnect.org",
        qrcode: true,
        rpc: { [config.chain_id]: config.rpc_url },
        chainId: config.chain_id,
      });

      this.initHandler(provider);

      //  Enable session (triggers QR Code modal)
      await provider
        .enable()
        .then((res) => {
          this.web3 = new providers.Web3Provider(provider);
          console.log(this.web3, "===========this.web3=======>", res);

          provider.connector.on("display_uri", (err, payload) => {
            const uri = payload.params[0];
            if (err) console.log(err, uri)
            else {
              const account = provider.selectedAddress;
              this.chainId = provider.networkVersion;
              this.handlerCallback([account]);
            }
          });
        })
        .catch((err) => {
          console.error(err, "WalletConnectProvider enable fail");
        });
    },

    //Just use metamask to log in. The API of CoinbaseSDK is not complete; all commands of metamask can be received and processed by Coinbase. As for metamask login or Coinbase login, it depends on which wallet trusts the Dapp URL
    async connectCoinbase(TargetChain) {
      //Coinbase Wallet
      this.targetChainId = Chain.getTargetChainId(TargetChain);

      const providerOptions = {
        coinbasewallet: {
          package: CoinbaseWalletSDK,
          options: {
            appName: "Web 3 Modal Demo",
            infuraId: process.env.INFURA_KEY,
          },
        },
      };
      const web3Modal = new Web3Modal({ providerOptions });
      let web3Provider = await web3Modal.connect();

      // const web3Provider = new CoinbaseWalletSDK({appName:"Test"}).makeWeb3Provider();
      // let config = Chain.getChainConfig(1)
      // if (this.targetChainId != -1) config = Chain.getChainConfig(this.targetChainId);
      // console.log("config==>", config)
      // web3Provider.setProviderInfo(config.rpc_url, config.chain_id)

      try {
        await web3Provider.enable();
      } catch (error) {
        console.error("User denied account access");
      }
      this.initHandler(web3Provider);
      this.web3 = new Web3(web3Provider);
      console.log(this.web3, "=========web3===========");
      this.web3.eth.getAccounts(function (error, result) {
        if (!error) {
          this.chainId = this.web3.currentProvider.networkVersion;
          this.handlerCallback([result]);
        }
      });
    },

    switchChain() {
      // if (!this.web3.currentProvider.isMetaMask) return console.log('Onto-wallet unsupport switchChain')
      let TargetChainId = this.targetChainId;
      if (TargetChainId == -1) TargetChainId = Chain.randomChainId;
      const config = Chain.getChainConfig(TargetChainId);
      this.web3.currentProvider.request({
        method: "wallet_addEthereumChain", //"wallet_switchEthereumChain"
        params: [
          {
            chainId: this.web3.utils.toHex(config.chain_id),
            chainName: config.name,
            nativeCurrency: {
              name: config.native_currency.name,
              symbol: config.native_currency.symbol,
              decimals: Number(config.native_currency.decimals),
            },
            rpcUrls: [config.rpc_url],
            blockExplorerUrls: [config.blockExplorerUrl],
          },
        ],
      });
    },

    login () {
      const time = Math.round(new Date().valueOf() / 1000);
      this.web3.eth.personal
        .sign("Sign this message to login bear DApp." + time, this.account)
        .then((res) => {
          this.signature = res + "|" + time;
        })
        .catch((err) => {
          console.log(err)
          this.connectHandler(ConnectStatus.CancelSignature)
        });
      this.connectContract();
    },

    async connectContract () {
      this.BearCoin = new this.web3.eth.Contract(
        ABI.BearCoin[this.chainId],
        addresses.BearCoin[this.chainId]
      );
      console.log("Contract BearCoin", this.BearCoin);

      this.BearNFT = new this.web3.eth.Contract(
        ABI.BearNFT[this.chainId],
        addresses.BearNFT[this.chainId]
      );
      console.log("Contract BearNFT", this.BearNFT);

      this.LandNFT = new this.web3.eth.Contract(
        ABI.LandNFT[this.chainId],
        addresses.LandNFT[this.chainId]
      );
      console.log("Contract LandNFT", this.LandNFT);

      this.IsaacCenter = new this.web3.eth.Contract(
        ABI.IsaacCenter[this.chainId],
        addresses.IsaacCenter[this.chainId]
      );
      console.log("Contract IsaacCenter", this.IsaacCenter);

      if (this.isConnectContracts()) {
        this.connectHandler(ConnectStatus.Success);
        this.balance = await this.web3.eth.getBalance(this.account);
        this.balanceEth = this.web3.utils.fromWei(this.balance, "ether");

        console.log(
          "Address and balance:",
          this.account,
          this.balance,
          this.balanceEth
        );
      }
    },

    analyzeSignature (signature) {
      let strTab = signature.split("0x");
      let _signature = "0x";
      for (let i = 1; i < strTab.length; i++) {
        _signature = _signature + strTab[i];
      }
      return _signature;
    },

    //BearCoin
    Allowance_BearCoin2Center (callback) {
      this.BearCoin.methods
        .allowance(this.account, addresses.IsaacCenter[this.chainId])
        .call((err, res) => {
          if (callback) callback(err, res);
        });
    },
    Approve_BearCoin2Center (callback) {
      this.BearCoin.methods
        .approve(addresses.IsaacCenter[this.chainId], this.defaultAddress)
        .send({ from: this.account }, (err, res) => {
          if (callback) callback(err, res);
        });
    },
    BalanceOf_BearCoin (callback) {
      this.BearCoin.methods.balanceOf(this.account).call((err, res) => {
        if (callback) callback(err, res);
      });
    },

    //BearNFT
    Allowance_BearNFT2Center (callback) {
      this.BearNFT.methods
        .isApprovedForAll(this.account, addresses.IsaacCenter[this.chainId])
        .call((err, res) => {
          if (callback) callback(err, res);
        });
    },
    Approve_BearNFT2Center (callback) {
      this.BearNFT.methods
        .setApprovalForAll(addresses.IsaacCenter[this.chainId], true)
        .send({ from: this.account }, (err, res) => {
          if (callback) callback(err, res);
        });
    },
    BalanceOf_BearNFT (callback) {
      this.BearNFT.methods.balanceOf(this.account).call((err, res) => {
        if (callback) callback(err, res);
      });
    },

    //LandNFT
    Allowance_LandNFT2Center (callback) {
      this.LandNFT.methods
        .isApprovedForAll(this.account, addresses.IsaacCenter[this.chainId])
        .call((err, res) => {
          if (callback) callback(err, res);
        });
    },
    Approve_LandNFT2Center (callback) {
      this.LandNFT.methods
        .setApprovalForAll(addresses.IsaacCenter[this.chainId], true)
        .send({ from: this.account }, (err, res) => {
          if (callback) callback(err, res);
        });
    },
    BalanceOf_LandNFT (callback ) {
      this.LandNFT.methods.balanceOf(this.account).call((err, res) => {
        if (callback) callback(err, res);
      });
    },
    OwnerOf_LandNFT (tokenId, callback) {
      this.LandNFT.methods.ownerOf(tokenId).call((err, res) => {
        if (callback) callback(res.toLowerCase() == this.account);
      });
    },

    //IsaacCenter
    //@params[tokenType] LandNFT=>1; BearCoin=>2;
    Deposit (
      tokenType,
      amount,
      tokenId,
      callback
    ) {
      amount =
        tokenType == 1
          ? amount
          : this.web3.utils.toWei(amount.toString(), "ether");
      this.IsaacCenter.methods
        .deposit(tokenType, amount, tokenId)
        .send({ from: this.account }, (err, res) => callback(err, res));
    },
    Withdraw (
      tokenType,
      orderId,
      amount,
      tokenId,
      newTokenId,
      quality,
      signature,
      callback 
    ) {
      this.IsaacCenter.methods
        .withdraw(
          tokenType,
          orderId,
          amount,
          tokenId,
          newTokenId,
          quality,
          this.analyzeSignature(signature)
        )
        .send({ from: this.account }, (err, res) => callback(err, res));
    },
    Handler_Deposit (callback ) {
      this.IsaacCenter.events
        .Deposit({ filter: { from: this.account } })
        .on("data", function (event) {
          console.log("deposit==>  ", event);
          if (this.transcationHash.indexOf(event.transcationHash) != -1)
            return;
          else this.transcationHash.push(event.transcationHash);

          if (callback) {
            callback(event.returnValues);
          }
        })
        .on("changed", function (event) {
          console.log("changed:", event);
        })
        .on("error", console.error);
    },
    Handler_Withdraw (callback ) {
      this.IsaacCenter.events
        .Withdraw({ filter: { to: this.account } })
        .on("data", function (event) {
          console.log("withdraw==>  ", event);
          if (this.transcationHash.indexOf(event.transcationHash) != -1)
            return;
          else this.transcationHash.push(event.transcationHash);

          if (callback) {
            callback(event.returnValues);
          }
        })
        .on("changed", function (event) {
          console.log("changed:", event);
        })
        .on("error", console.error);
    },

    isConnectContracts () {
      if (!this.account) {
        console.log("Get account null");
        this.connectWeb3();
        return false;
      }
      if (
        !this.BearCoin ||
        !this.BearNFT ||
        !this.LandNFT ||
        !this.IsaacCenter
      ) {
        console.log("Connect contract error");
        this.connectContract();
        return false;
      }
      return true;
    },
  },
  created() {
    // this.checkSdk();
    this.init();
    console.log(this, "======================>")
    window.ContractVueObj = this;
  },
};
</script>
